import styled from "styled-components";

export const DivBackgroundImage = styled.div`
  background: url(${props => props.image_url}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;

  padding-top: 100px;
  min-height: ${props => props.min_height}px;

  overflow-y: auto;
`;

export const DivBackgroundImageFixed = styled.div`
  background: url(${props => props.image_url}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;

  padding-top: 100px;
  min-height: ${props => props.min_height}px;

  position: fixed;
  width: 100%;
  height: 100%;

  overflow-y: auto;
`;