import React from 'react';

import { DivBackgroundImage } from '../Style';

import './Home.css'

function Home() {
  localStorage.setItem('ALPHA_TYPE', 0);
  localStorage.setItem('S_CHAR', "a");
  localStorage.setItem('E_CHAR', "b");

  return (
    <DivBackgroundImage image_url='https://source.unsplash.com/2gNx4aKQYvY/1920x1080' min_height={window.innerHeight}>
      <div className='center-rect-upper'>
        <p>Welcome to etour</p>
      </div>
      <div className='center-rect-lower'>
        <p>영화, 음악, 그리고 책을</p>
        <p>사랑하고 수집하는</p>
        <p>나만의 공간</p>
      </div>
    </DivBackgroundImage>
  );
}

export default Home;
