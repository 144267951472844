import React, { useState } from 'react';

import JoditEditor from 'jodit-react';

import gql from "graphql-tag";
import { useMutation } from "react-apollo";

import { DivBackgroundImageFixed } from '../../Style';

import './BoardAdd.css'

const ADD_BOARD_DETAIL_INFO = gql`
    mutation addBoardInfo($id: String, $title: String, $content: String, $write_date: String) {
      addBoardInfo(id: $id, title: $title, content: $content, write_date: $write_date) {
          id
          title
          content
          write_date
        }
    }
`;

function BoardAdd() {
  let [title, setTitle] = useState('');
  let [content, setContent] = useState('');
  const [alertshow, setAlertShow] = useState(false);

  const [addBoardInfo] = useMutation(ADD_BOARD_DETAIL_INFO);

  function clickEvent() {
    setContent(content);
    setTitle(title);
    if (title === '')
    {
        setAlertShow(true);
        return;
    }

    const today = new Date();
    const year_id = today.getFullYear();
    const month_id = ("0" + (today.getMonth() + 1)).slice(-2);
    const day_id = ("0" + today.getDate()).slice(-2);
    const hours_id = ("0" + today.getHours()).slice(-2);
    const minutes_id = ("0" + today.getMinutes()).slice(-2);
    const seconds_id = ("0" + today.getSeconds()).slice(-2);
    const milliseconds_id = ("0" + today.getMilliseconds()).slice(-3);
    const id = year_id + '_' + month_id + '_' + day_id + '_' + hours_id + '_' + minutes_id + '_' + seconds_id + '_' + milliseconds_id;
    
    const write_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + minutes_id

    addBoardInfo({variables: {
      id,
      title,
      content,
      write_date,
    }});

    setTimeout(() => {
        window.location.href = `/boarddetail/${id}`;
    }, 3000);
  }

  return (
    <DivBackgroundImageFixed image_url='https://source.unsplash.com/2gNx4aKQYvY/1920x1080' min_height={window.innerHeight}>
      <div className="container">
        <div className="card border-0 shadow my-5">

          <div className="row pl-4 pr-4 ml-5 mr-5 mt-3 text-center">
            <div className="col-9">
                {alertshow && (
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>제목</strong>을 입력해주세요.
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setAlertShow(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                )}
            </div>
            <div className="col text-right">
                <button className="btn btn-secondary edit-button-align" onClick={clickEvent}>
                    <i className="fa fa-save"></i>
                </button>
            </div>
          </div>

          <div className="row pl-4 pr-4 ml-5 mr-5 mb-1">
          <hr width="100%" align="center"></hr>
            <div className="p-4 col-md-12">
              <p className="lead font-weight-bold">
                <input type="text" id="title" className="form-control" required onChange={(event) => {
                  title = event.target.value;
                }} />
              </p>

              <div className="row pb-4 text-center">
                <hr width="100%" align="center"></hr>
              </div>

              <JoditEditor
                value={content}
                config={{
                  spellcheck: false,
                  showCharsCounter: false,
                  showWordsCounter: false,
                  showXPathInStatusbar: false,
                  allowResizeY: false,
                  height: 320,
                  readonly: false,
                  uploader: { insertImageAsBase64URI: true },
                  buttons: ['bold', 'strikethrough', 'underline', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|', 'image', 'table', '|', 'align', 'hr'],
                  toolbarAdaptive: false,
                  placeholder: '',
                  disablePlugins: ['paste', 'drag-and-drop', 'drag-and-drop-element'],
                  askBeforePasteHTML: false,
                  askBeforePasteFromWord: false
                }}
                onBlur={newContent => { content = newContent; }}
                onChange={newContent => {}}
              />
            </div>
          </div>

          <div className="row pl-4 pr-4 ml-5 mr-5 mb-3 text-center">
            <hr width="100%" align="center"></hr>
            <div className="col-9">
                {alertshow && (
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>제목</strong>을 입력해주세요.
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setAlertShow(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                )}
            </div>
            <div className="col text-right">
                <button className="btn btn-secondary edit-button-align" onClick={clickEvent}>
                    <i className="fa fa-save"></i>
                </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row p-4 ml-5 mr-5 mb-3 text-center">
      </div>
    </DivBackgroundImageFixed>
  );
}

export default BoardAdd;
