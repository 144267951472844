import React from 'react';

import gql from "graphql-tag";
import { Mutation } from "react-apollo";

import { DivBackgroundImage } from '../Style';

import './Login.css';

const AUTH_TOKEN = 'auth-token';

const LOGIN_USER_INFO = gql`
    mutation loginUserInfo($name: String!, $pass: String!) {
        loginUserInfo(name: $name, pass: $pass)
    }
`;

function Login() {
    return (
        <DivBackgroundImage image_url='https://source.unsplash.com/2gNx4aKQYvY/1920x1080' min_height={window.innerHeight}>
        <div className="container">
            <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div className="card card-signin my-5">
                <div className="card-body">
                    <h5 className="card-title text-center">S I G N - I N</h5>
                    <Mutation mutation={LOGIN_USER_INFO} onCompleted={(data) => {
                        localStorage.setItem(AUTH_TOKEN, data.loginUserInfo);
                        window.location.href = '/';
                        }}>
                        {mutate => (
                        <form className="form-signin" onSubmit={(event) => {
                            event.preventDefault();
                            mutate({
                            variables: {
                                name: event.target.inputName.value,
                                pass: event.target.inputPassword.value,
                            }
                            });
                        }}>
                            <div className="form-label-group">
                                <input type="text" id="inputName" className="form-control" placeholder="" required autoFocus />
                                <label htmlFor="inputName">Name</label>
                            </div>

                            <div className="form-label-group">
                                <input type="password" id="inputPassword" className="form-control" placeholder="" required />
                                <label htmlFor="inputPassword">Password</label>
                            </div>
                            <hr className="my-4" />
                            <button className="btn btn-lg btn-primary btn-block text-uppercase" verticalalign="middle" type="submit">
                                <h4><i className="fa fa-sign-in"></i></h4>
                            </button>
                        </form>
                        )}
                    </Mutation>
                </div>
                </div>
            </div>
            </div>
        </div>
    </DivBackgroundImage>
  );
}

export default Login;