import React from 'react';
import { NavLink } from 'react-router-dom';

import './Navigator.css'

const AUTH_TOKEN = 'auth-token';

function Navigator() {
    const token = localStorage.getItem(AUTH_TOKEN);

    function clickEventReset()
    {
        localStorage.setItem('ALPHA_TYPE', 0);
    }
    
    function clickEvent() {
        localStorage.removeItem(AUTH_TOKEN);
        window.location.href = '/';
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light shadow fixed-top">
            <div className="container">
                <NavLink  exact to='/' className='navbar-brand brand-style'>E T O U R</NavLink >
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item mr-5">
                            <NavLink to='/movielist/1' className='nav-link link-style' onClick={clickEventReset}>M O V I E</NavLink>
                        </li>
                        <li className="nav-item mr-5">
                            <NavLink to='/musiclist/1' className='nav-link link-style' onClick={clickEventReset}>M U S I C</NavLink>
                        </li>
                        <li className="nav-item mr-5">
                            <NavLink to='/booklist/1' className='nav-link link-style' onClick={clickEventReset}>B O O K</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to='/boardlist/1' className='nav-link link-style' onClick={clickEventReset}>B O A R D</NavLink>
                        </li>
                        {!token && (<li className="nav-item ml-5">
                            <NavLink to='/login' className='nav-link link-style' onClick={clickEventReset}>S I G N - I N</NavLink>
                        </li>)}
                        {token && (<li className="nav-item ml-5">
                            <NavLink to='#' className='nav-link link-style signout-style' onClick={clickEvent}>S I G N - O U T</NavLink>
                        </li>)}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navigator;
