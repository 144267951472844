import React from 'react';
import { Link } from 'react-router-dom';

import gql from "graphql-tag";
import { Query } from "react-apollo";

import { DivBackgroundImage } from '../../Style';

import './BoardList.css'

import BoardCard from '../../component/BoardCard';
import PageNumberBar from '../../component/PageNumberBar';

const AUTH_TOKEN = 'auth-token';
const LAST_PAGE = 'last_page';

const GET_BOARD_INFO = gql`
    query($page: Int) {
        getBoardInfo(page: $page) {
          total_count
          board_list {
            id
            title
            write_date
          }
        }
    }
`;

function BoardList(props) {
  localStorage.setItem('ALPHA_TYPE', 0);
  localStorage.setItem('S_CHAR', "a");
  localStorage.setItem('E_CHAR', "b");

  const token = localStorage.getItem(AUTH_TOKEN);
  localStorage.setItem(LAST_PAGE, props.location.pathname);

  return (
    <Query query={GET_BOARD_INFO} variables={{page: parseInt(props.match.params.page)}}>
      {({ loading, error, data }) => {
        if (loading) return '';
        if (error) return `Error! ${error.message}`;

        const total_count = data.getBoardInfo.total_count;
        const book_info_list = data.getBoardInfo.board_list;

        return (
          <DivBackgroundImage image_url='https://source.unsplash.com/2gNx4aKQYvY/1920x1080' min_height={window.innerHeight}>
            <div className="container">
              <div className="card border-0 shadow my-5">
                <div className="row pl-4 pr-4 ml-5 mr-5 list-top-div">
                  <div className="col">
                    <h4>{total_count}</h4>
                  </div>
                  <div>
                    {token && (<Link to='/boardadd'>
                      <button className="btn btn-secondary">
                        <i className="fa fa-plus"></i>
                      </button>
                    </Link>)}
                  </div>

                  <div className="col-7">
                    <form onSubmit={(event) => {
                        event.preventDefault();
                        window.location.href = `/boardresultlist/${event.target.searchtext.value}/1`;
                    }}>
                      <div className="input-group">
                          <input type="text" id="searchtext" className="form-control" placeholder="" required />
                          <span className="input-group-append">
                              <button className="btn btn-secondary" type="submit">
                                  <i className="fa fa-search"></i> 
                              </button>
                          </span>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="row pl-4 pr-4 ml-5 mr-5">
                  <hr width="100%" align="center"></hr>
                </div>
                
                <div className="row pl-4 pr-4 pb-4 ml-5 mr-5">
                  {book_info_list.map((info, index) => (
                        <BoardCard info={info} detail={`/boarddetail/${info.id}`} key={index} />
                  ))}
                </div>

                <div className="row pl-4 pr-4 ml-5 mr-5 mb-3 text-center">
                  <hr width="100%" align="center"></hr>
                  <div className="col" align="left">
                    {parseInt(props.match.params.page) > 1 && (
                      <Link to={`/boardlist/${parseInt(props.match.params.page) - 1}`}>
                        <button className="btn btn-secondary">
                          <i className="fa fa-long-arrow-left icon_size"></i>
                        </button>
                    </Link>
                    )}
                  </div>
                  <div className="col-7">
                    <PageNumberBar link_text={'/boardlist/'} curr_page={parseInt(props.match.params.page)} max_count={total_count} />
                  </div>
                  <div className="col" align="right">
                    {parseInt(props.match.params.page - 1) * 52 + 52 < total_count && (
                      <Link to={`/boardlist/${parseInt(props.match.params.page) + 1}`}>
                        <button className="btn btn-secondary">
                          <i className="fa fa-long-arrow-right icon_size"></i>
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
      
              </div>
            </div>
            <button className="btn btn-light scroll" onClick={ () => { window.scrollTo(0, 0); }}>
              <i className="fa fa-angle-double-up"></i>
            </button>
          </DivBackgroundImage>
        );
      }}
    </Query>
  );
}

export default BoardList;
