import React, { useState } from 'react';

import './SearchBar.css'

function SearchBar(props) {
    const [search_type, setSearchType] = useState('1');

    return (
        <div className="input-group">
            {props.page_type === 1 && (<select className="form-control search_type_width" id="searchtype" value={search_type} onChange={(event) => {
                setSearchType(event.target.value);
            }}>
                <option value="1">제목</option>
                <option value="2">감독</option>
                <option value="3">출연</option>
            </select>)}
            {props.page_type === 2 && (<select className="form-control search_type_width" id="searchtype" value={search_type} onChange={(event) => {
                setSearchType(event.target.value);
            }}>
                <option value="1">제목</option>
                <option value="2">작곡가</option>
                <option value="3">지휘자</option>
                <option value="4">아티스트</option>
                <option value="5">오케스트라</option>
            </select>)}
            {props.page_type === 3 && (<select className="form-control search_type_width" id="searchtype" value={search_type} onChange={(event) => {
                setSearchType(event.target.value);
            }}>
                <option value="1">제목</option>
                <option value="2">저자</option>
                <option value="3">역자</option>
                <option value="4">출판사</option>
            </select>)}
            <input type="text" id="searchtext" className="form-control" placeholder="" required />
            <span className="input-group-append">
                <button className="btn btn-secondary" type="submit">
                    <i className="fa fa-search"></i> 
                </button>
            </span>
        </div>
    );
}

export default SearchBar;
