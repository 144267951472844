import React, { useState } from 'react';

import gql from "graphql-tag";
import { useMutation } from "react-apollo";

import { DivBackgroundImage } from '../../Style';

import './BookAdd.css'

const ADD_BOOK_DETAIL_INFO = gql`
    mutation addBookInfo($id: String, $imagefile: Upload, $title: String, $sub_title: String, $type: String, $watched: String, $author: String, $translator: String, $publisher: String, $release_date: String, $story: String, $review: String, $edit_date: String) {
        addBookInfo(id: $id, imagefile: $imagefile, title: $title, sub_title: $sub_title, type: $type, watched: $watched, author: $author, translator: $translator, publisher: $publisher, release_date: $release_date, story: $story, review: $review, edit_date: $edit_date) {
          id
          imagefile
          title
          sub_title
          type
          watched
          author
          translator
          publisher
          release_date
          story
          review
          edit_date
        }
    }
`;

function BookAdd(props) {
    const [imagefile, setImageFile] = useState(null);
    const [imagefileview, setImageFileView] = useState('');
    const [imagefilename, setImageFileName] = useState('');
    const [title, setTitle] = useState('');
    const [sub_title, setSubTitle] = useState('');
    const [type, setType] = useState('BOOK');
    const [watched, setWatched] = useState('n');
    const [author, setAuthor] = useState('');
    const [translator, setTranslator] = useState('');
    const [publisher, setPublisher] = useState('');
    const [release_date, setReleaseDate] = useState('');
    const [story, setStory] = useState('');
    const [review, setReview] = useState('');
    const [alertshow, setAlertShow] = useState(false);

    const [addBookInfo] = useMutation(ADD_BOOK_DETAIL_INFO);

    function clickEvent() {
        if (imagefileview === '' || title === '')
        {
            setAlertShow(true);
            return;
        }

        const today = new Date();
        const year_id = today.getFullYear();
        const month_id = ("0" + (today.getMonth() + 1)).slice(-2);
        const day_id = ("0" + today.getDate()).slice(-2);
        const hours_id = ("0" + today.getHours()).slice(-2);
        const minutes_id = ("0" + today.getMinutes()).slice(-2);
        const seconds_id = ("0" + today.getSeconds()).slice(-2);
        const milliseconds_id = ("0" + today.getMilliseconds()).slice(-3);
        const id = year_id + '_' + month_id + '_' + day_id + '_' + hours_id + '_' + minutes_id + '_' + seconds_id + '_' + milliseconds_id;
        const edit_date = id;
        
        addBookInfo({variables: {
            id,
            imagefile,
            title,
            sub_title,
            type,
            watched,
            author,
            translator,
            publisher,
            release_date,
            story,
            review,
            edit_date,
        }});

        setTimeout(() => {
            window.location.href = `/bookdetail/${id}`;
        }, 3000);
    }

    return (
        <DivBackgroundImage image_url='https://source.unsplash.com/2gNx4aKQYvY/1920x1080' min_height={window.innerHeight}>
        <div className="container">
            <div className="card border-0 shadow my-5">

            <div className="row pl-4 pr-4 ml-5 mr-5 mt-3 text-center">
                <div className="col-9">
                    {alertshow && (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>이미지</strong>와 <strong>제목</strong>을 입력해주세요.
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setAlertShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    )}
                </div>
                <div className="col text-right">
                    <button className="btn btn-secondary edit-button-align" onClick={clickEvent}>
                        <i className="fa fa-save"></i>
                    </button>
                </div>
                <hr width="100%" align="center"></hr>
            </div>

            <div className="row ml-5 mr-5 mt-3">
                <div className="col-md-6 mb-4">
                <div className="p-4">
                    <img src={imagefileview} className="img-fluid mx-auto d-block add-image" alt="" />
                    <br></br>
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="imagefile" onChange={(event) => {
                            if(event.target.files[0] !== undefined)
                            {
                                setImageFile(event.target.files[0]);
                                setImageFileView(URL.createObjectURL(event.target.files[0]));
                                setImageFileName(event.target.files[0].name);
                            }
                            else
                            {
                                setImageFile(null);
                                setImageFileView('');
                                setImageFileName('');
                            }
                        }} />
                        <label className="custom-file-label file-label-width" htmlFor="image-file-path">{imagefilename}</label>
                    </div>
                </div>
                </div>

                <div className="col-md-6 mb-4">
                <div className="p-4">
                    <p className="lead font-weight-bold">
                        <label htmlFor="title">제목</label>
                        <input type="text" id="title" className="form-control" value={title} required onChange={(event) => {
                            setTitle(event.target.value);
                        }} />
                    </p>
                    <p className="lead font-weight-bold">
                        <label htmlFor="sub_title">영어 제목</label>
                        <input type="text" id="sub_title" className="form-control" value={sub_title} onChange={(event) => {
                            setSubTitle(event.target.value);
                        }} />
                    </p>

                    <br></br>
                    
                    <p>
                    <select className="form-control" id="type" value={type} required onChange={(event) => {
                        setType(event.target.value);
                    }}>
                        <option value="BOOK">BOOK</option>
                        <option value="COMIC">COMIC</option>
                    </select>
                    </p>

                    <div className="form-check">
                        <input className="form-check-input check-box-align" type="checkbox" id="watched" checked={watched === 'y'} onChange={(event) => {
                            if (event.target.checked) setWatched('y');
                            else setWatched('n');
                        }} />
                        <p className="lead">
                            <label className="form-check-label" htmlFor="watched"><span className="badge badge-success">Read</span></label>
                        </p>
                    </div>

                    <br></br>

                    <p className="lead font-weight-bold">저자</p>
                    <p><input type="text" id="author" className="form-control" value={author} onChange={(event) => {
                            setAuthor(event.target.value);
                    }} /></p>
                    <br></br>
                    <p className="lead font-weight-bold">역자</p>
                    <p><input type="text" id="translator" className="form-control" value={translator} onChange={(event) => {
                            setTranslator(event.target.value);
                    }} /></p>
                    <br></br>
                    <p className="lead font-weight-bold">출판사</p>
                    <p><input type="text" id="publisher" className="form-control" value={publisher} onChange={(event) => {
                            setPublisher(event.target.value);
                    }} /></p>
                    <br></br>
                    <p className="lead font-weight-bold">출판일</p>
                    <p><input type="text" id="release_date" className="form-control" value={release_date} onChange={(event) => {
                            setReleaseDate(event.target.value);
                    }} /></p>
                </div>
                </div>

                <div className="row pt-2 pl-5 pb-2 edit-width-full">
                    <div className="book-edit-bottom edit-width-full">
                        <div className="pb-4">
                            <p className="lead font-weight-bold">줄거리</p>
                            <p><textarea id="story" className="form-control edit-textarea-height" value={story} onChange={(event) => {
                                setStory(event.target.value);
                            }} /></p>
                        </div>
                        <div>
                            <p className="lead font-weight-bold">감상</p>
                            <p><textarea id="review" className="form-control edit-textarea-height" value={review} onChange={(event) => {
                                setReview(event.target.value);
                            }} /></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row pl-4 pr-4 ml-5 mr-5 mb-3 text-center">
                <hr width="100%" align="center"></hr>
                <div className="col-9">
                    {alertshow && (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>이미지</strong>와 <strong>제목</strong>을 입력해주세요.
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setAlertShow(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    )}
                </div>
                <div className="col text-right">
                    <button className="btn btn-secondary edit-button-align" onClick={clickEvent}>
                        <i className="fa fa-save"></i>
                    </button>
                </div>
            </div>

            </div>
        </div>
        </DivBackgroundImage>
    );
}

export default BookAdd;
