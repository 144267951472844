import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-boost';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';

import { SERVER_URL } from './ConnectInfo'

import Navigator from './component/Navigator';
import Home from './page/Home';
import Login from './page/Login';

import MovieList from './page/movie/MovieList';
import MovieResultList from './page/movie/MovieResultList';
import MovieReviewList from './page/movie/MovieReviewList';
import MovieReviewResultList from './page/movie/MovieReviewResultList';
import MovieDetail from './page/movie/MovieDetail';
import MovieAdd from './page/movie/MovieAdd';
import MovieEdit from './page/movie/MovieEdit';

import MusicList from './page/music/MusicList';
import MusicResultList from './page/music/MusicResultList';
import MusicReviewList from './page/music/MusicReviewList';
import MusicReviewResultList from './page/music/MusicReviewResultList';
import MusicDetail from './page/music/MusicDetail';
import MusicAdd from './page/music/MusicAdd';
import MusicEdit from './page/music/MusicEdit';

import BookList from './page/book/BookList';
import BookResultList from './page/book/BookResultList';
import BookReviewList from './page/book/BookReviewList';
import BookReviewResultList from './page/book/BookReviewResultList';
import BookDetail from './page/book/BookDetail';
import BookAdd from './page/book/BookAdd';
import BookEdit from './page/book/BookEdit';

import BoardList from './page/board/BoardList';
import BoardResultList from './page/board/BoardResultList';
import BoardDetail from './page/board/BoardDetail';
import BoardAdd from './page/board/BoardAdd';
import BoardEdit from './page/board/BoardEdit';

const AUTH_TOKEN = 'auth-token';

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const uploadLink = createUploadLink({ uri: SERVER_URL });

const client = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]),
  cache: new InMemoryCache()
});

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Navigator />

        <Route path={'/'} exact component={Home} />
        <Route path={'/login'} exact component={Login} />

        <Route path={'/movielist/:page'} component={MovieList} />
        <Route path={'/movieresultlist/:searchtext/:page'} component={MovieResultList} />
        <Route path={'/moviereviewlist/:page'} component={MovieReviewList} />
        <Route path={'/moviereviewresultlist/:searchtext/:page'} component={MovieReviewResultList} />
        <Route path={'/moviedetail/:id'} component={MovieDetail} />
        <Route path={'/movieadd'} component={MovieAdd} />
        <Route path={'/movieedit/:id'} component={MovieEdit} />

        <Route path={'/musiclist/:page'} component={MusicList} />
        <Route path={'/musicresultlist/:searchtext/:page'} component={MusicResultList} />
        <Route path={'/musicreviewlist/:page'} component={MusicReviewList} />
        <Route path={'/musicreviewresultlist/:searchtext/:page'} component={MusicReviewResultList} />
        <Route path={'/musicdetail/:id'} component={MusicDetail} />
        <Route path={'/musicadd'} component={MusicAdd} />
        <Route path={'/musicedit/:id'} component={MusicEdit} />

        <Route path={'/booklist/:page'} component={BookList} />
        <Route path={'/bookresultlist/:searchtext/:page'} component={BookResultList} />
        <Route path={'/bookreviewlist/:page'} component={BookReviewList} />
        <Route path={'/bookreviewresultlist/:searchtext/:page'} component={BookReviewResultList} />
        <Route path={'/bookdetail/:id'} component={BookDetail} />
        <Route path={'/bookadd'} component={BookAdd} />
        <Route path={'/bookedit/:id'} component={BookEdit} />

        <Route path={'/boardlist/:page'} component={BoardList} />
        <Route path={'/boardresultlist/:searchtext/:page'} component={BoardResultList} />
        <Route path={'/boarddetail/:id'} component={BoardDetail} />
        <Route path={'/boardadd'} component={BoardAdd} />
        <Route path={'/boardedit/:id'} component={BoardEdit} />

      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
