import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import './PageNumberBar.css'

function PageNumberBar(props) {
    const currPage = props.curr_page;
    const maxPage = Math.floor(props.max_count / 52) + ((props.max_count % 52) === 0 ? 0 : 1);

    let min_value = currPage - 5;
    let max_value = currPage + 5;
    if(currPage < 1 + 5)
    {
        min_value = 1;
        max_value = 1 + 10;

        if(max_value > maxPage) max_value = maxPage;
    }
    else if(currPage > maxPage - 5)
    {
        min_value = maxPage - 10;
        max_value = maxPage;
        
        if(min_value < 1) min_value = 1;
    }

    let page_array_left = [];
    for (let i = min_value; i < currPage; i++)
    {
        page_array_left.push(i);
    }

    let page_array_right = [];
    for (let i = currPage + 1; i <= max_value; i++)
    {
        page_array_right.push(i);
    }

    return (
        <div>
            {page_array_left.map((value, index) => (
                <Fragment key={index}>
                    <Link to={`${props.link_text}${value}`}>
                        <button className="btn btn-secondary btn-sm">
                            {value}
                        </button>
                    </Link>
                    <span> </span>
                </Fragment>
            ))}            
            <Link to={`${props.link_text}${currPage}`}>
                <button className="btn btn-dark btn-sm">
                    {currPage}
                </button>
            </Link>
            {page_array_right.map((value, index) => (
                <Fragment key={index}>
                    <span> </span>
                    <Link to={`${props.link_text}${value}`}>
                        <button className="btn btn-secondary btn-sm">
                            {value}
                        </button>
                    </Link>
                </Fragment>
            ))}
        </div>
    );
}

export default PageNumberBar;