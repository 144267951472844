import React from 'react';
import { Link } from 'react-router-dom';

import { S3_IMAGE_URL } from '../ConnectInfo';

import './Card.css'

function Card(props) {
    return (
        <div className="col-sm-6 col-md-4 col-lg-3 mt-4">
            <div className="card-wrap">
                <Link to={`${props.detail}`}>
                    <img className="card-img-top" src={S3_IMAGE_URL + props.info.id} alt="" />
                    <div className="card-block">
                        <h4 className="card-title">{props.info.title}</h4>
                        <div className="card-meta">
                            <p className="m-0">{props.info.type}</p>
                            {props.info.watched === 'y' && (
                                <span className="badge badge-success">{props.watch_text}</span>
                            )}
                            <span> </span>
                            {props.info.review !== '' && (
                                <span className="badge badge-info">Review</span>
                            )}
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default Card;
