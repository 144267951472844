import React from 'react';
import { Link } from 'react-router-dom';

import './BoardCard.css'

function BoardCard(props) {
    return (
        <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
            <div className="card board-card">
                <Link to={`${props.detail}`}>
                    <div className="card-body">
                        <h5 className="card-title">{props.info.title}</h5>
                        <h6 className="card-subtitle mb-2 text-muted text-right">{props.info.write_date}</h6>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default BoardCard;
