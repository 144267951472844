import React from 'react';
import { Link } from 'react-router-dom';

import gql from "graphql-tag";
import { Query, useMutation } from "react-apollo";

import { DivBackgroundImage } from '../../Style';

import './BoardDetail.css'

const AUTH_TOKEN = 'auth-token';

const GET_BOARD_DETAIL_INFO = gql`
  query getBoardDetailInfo($id: String) {
    getBoardDetailInfo(id: $id) {
      id
      title
      content
      write_date
    }
  }
`;

const DELETE_BOARD_DETAIL_INFO = gql`
    mutation deleteBoardInfo($id: String) {
      deleteBoardInfo(id: $id)
    }
`;

function BoardDetail(props) {
  const token = localStorage.getItem(AUTH_TOKEN);
  const [deleteBoardInfo] = useMutation(DELETE_BOARD_DETAIL_INFO);

  function clickEvent() {
    deleteBoardInfo({variables: {
        id: props.match.params.id
    }});

    setTimeout(() => {
      window.location.href = '/boardlist/1';
    }, 3000);
  }

  return (
    <Query query={GET_BOARD_DETAIL_INFO} variables={{id: props.match.params.id}}>
      {({ loading, error, data }) => {
        if (loading) return '';
        if (error) return `Error! ${error.message}`;

        const board_detail_info = data.getBoardDetailInfo;

        return (
          <React.Fragment>
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">삭제 확인</h5>
                    <button type="button" className="close" data-dismiss="modal">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <strong>삭제</strong> 하시겠습니까?
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={clickEvent}>
                      <i className="fa fa-trash-o"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <DivBackgroundImage image_url='https://source.unsplash.com/2gNx4aKQYvY/1920x1080' min_height={window.innerHeight}>
              <div className="container">
                <div className="card border-0 shadow my-5">

                  <div className="row pl-4 pr-4 ml-5 mr-5 list-top-div">
                    <div className="col">
        
                    </div>
        
                    <div className="col-7 text-right">
                      {token && (<Link to={`/boardedit/${props.match.params.id}`}>
                        <button className="btn btn-secondary">
                          <i className="fa fa-pencil"></i>
                        </button>
                      </Link>)}

                    </div>

                    <div className="col-0">
                      {token && (<button className="btn btn-danger" data-toggle="modal" data-target="#exampleModal">
                        <i className="fa fa-trash-o"></i>
                      </button>)}
                    </div>
                  </div>
        
                  <div className="row pl-4 pr-4 ml-5 mr-5 mt-2">
                    <hr width="100%" align="center"></hr>
                  </div>

                  <div className="row pl-4 pr-4 ml-5 mr-5">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <h1 className="card-title board-title">{board_detail_info.title}</h1>
                      <h6 className="card-subtitle mb-2 text-muted text-right">{board_detail_info.write_date}</h6>
                    </div>
                  </div>

                  <div className="row pl-4 pr-4 ml-5 mr-5">
                    <hr width="100%" align="center"></hr>
                  </div>

                  <div className="pl-5 pr-5 ml-5 mr-5 mt-2 mb-2 board-content">
                    <div dangerouslySetInnerHTML={ {__html: board_detail_info.content} }>
                    </div>
                  </div>

                  <div className="row pl-4 pr-4 ml-5 mr-5 mb-5 text-center">
                  </div>
                  
                </div>
              </div>
            </DivBackgroundImage>
          </React.Fragment>
        );
      }}
    </Query>
  );
}

export default BoardDetail;
