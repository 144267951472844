import React from 'react';
import { Link } from 'react-router-dom';

import gql from "graphql-tag";
import { Query } from "react-apollo";

import { DivBackgroundImage } from '../../Style';

import './MovieReviewList.css'

import Card from '../../component/Card';
import SearchBar from '../../component/SearchBar';
import PageNumberBar from '../../component/PageNumberBar';
import AlphabetBar from '../../component/AlphabetBar';

const AUTH_TOKEN = 'auth-token';
const LAST_PAGE = 'last_page';
const SEARCH_TYPE = 'search_type';

const GET_MOVIE_INFO = gql`
    query($page: Int, $show_type: Int, $alphabet_type: Int, $start_char: String, $end_char: String) {
        getMovieInfo(page: $page, show_type: $show_type, alphabet_type: $alphabet_type, start_char: $start_char, end_char: $end_char) {
          total_count
          movie_list {
            id
            title
            type
            watched
            review
          }
        }
    }
`;

function MovieReviewList(props) {
  const token = localStorage.getItem(AUTH_TOKEN);
  localStorage.setItem(LAST_PAGE, props.location.pathname);

  return (
    <Query query={GET_MOVIE_INFO} variables={{page: parseInt(props.match.params.page), show_type: 1, alphabet_type: parseInt(localStorage.getItem('ALPHA_TYPE')), start_char: localStorage.getItem('S_CHAR'), end_char: localStorage.getItem('E_CHAR')}}>
      {({ loading, error, data }) => {
        if (loading) return '';
        if (error) return `Error! ${error.message}`;

        const total_count = data.getMovieInfo.total_count;
        const movie_info_list = data.getMovieInfo.movie_list;

        return (
          <DivBackgroundImage image_url='https://source.unsplash.com/2gNx4aKQYvY/1920x1080' min_height={window.innerHeight}>
            <div className="container">
              <div className="card border-0 shadow my-5">
                <div className="row pl-4 pr-4 ml-5 mr-5 list-top-div">
                  <div className="col">
                    <h4>{total_count}</h4>
                    <Link to='/movielist/1'>
                      <span className="badge badge-secondary">All</span>
                    </Link>
                    </div>
                    <div>
                    {token && (<Link to='/movieadd'>
                      <button className="btn btn-secondary">
                        <i className="fa fa-plus"></i>
                      </button>
                    </Link>)}
                  </div>
      
                  <div className="col-7">
                    <form onSubmit={(event) => {
                        event.preventDefault();
                        localStorage.setItem(SEARCH_TYPE, event.target.searchtype.value);
                        window.location.href = `/movieresultlist/${event.target.searchtext.value}/1`;
                    }}>
                      <SearchBar page_type={1}/>
                    </form>
                  </div>
                </div>
      
                <div className="row pl-4 pr-4 ml-5 mr-5">
                  <hr width="100%" align="center"></hr>
                </div>
                <div className="row pl-4 pr-4 ml-5 mr-5">
                  <AlphabetBar link_text={'/moviereviewlist/'}/>
                </div>
                <div className="row pl-4 pr-4 ml-5 mr-5">
                  <hr width="100%" align="center"></hr>
                </div>
                
                <div className="row pl-4 pr-4 pb-4 ml-5 mr-5">
                  {movie_info_list.map((info, index) => (
                        <Card info={info} watch_text={'Watch'} detail={`/moviedetail/${info.id}`} key={index} />
                  ))}
                </div>

                <div className="row pl-4 pr-4 ml-5 mr-5 mb-3 text-center">
                  <hr width="100%" align="center"></hr>
                  <div className="col" align="left">
                    {parseInt(props.match.params.page) > 1 && (
                      <Link to={`/moviereviewlist/${parseInt(props.match.params.page) - 1}`}>
                        <button className="btn btn-secondary">
                          <i className="fa fa-long-arrow-left icon_size"></i>
                        </button>
                    </Link>
                    )}
                  </div>
                  <div className="col-7">
                    <PageNumberBar link_text={'/moviereviewlist/'} curr_page={parseInt(props.match.params.page)} max_count={total_count} />
                  </div>
                  <div className="col" align="right">
                    {parseInt(props.match.params.page - 1) * 52 + 52 < total_count && (
                      <Link to={`/moviereviewlist/${parseInt(props.match.params.page) + 1}`}>
                        <button className="btn btn-secondary">
                          <i className="fa fa-long-arrow-right icon_size"></i>
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
      
              </div>
            </div>
            <button className="btn btn-light scroll" onClick={ () => { window.scrollTo(0, 0); }}>
              <i className="fa fa-angle-double-up"></i>
            </button>
          </DivBackgroundImage>
        );
      }}
    </Query>
  );
}

export default MovieReviewList;
