import React from 'react';
import { Link } from 'react-router-dom';

import gql from "graphql-tag";
import { Query, useMutation } from "react-apollo";

import { S3_IMAGE_URL } from '../../ConnectInfo'
import { DivBackgroundImage } from '../../Style';

import SearchBar from '../../component/SearchBar';

import './MovieDetail.css'

const AUTH_TOKEN = 'auth-token';
const SEARCH_TYPE = 'search_type';

const GET_MOVIE_DETAIL_INFO = gql`
    query getMovieDetailInfo($id: String) {
        getMovieDetailInfo(id: $id) {
          id
          title
          sub_title
          type
          watched
          director
          actor
          story
          review
        }
    }
`;

const DELETE_MOVIE_DETAIL_INFO = gql`
    mutation deleteMovieInfo($id: String) {
        deleteMovieInfo(id: $id)
    }
`;

function MovieDetail(props) {
  const token = localStorage.getItem(AUTH_TOKEN);
  const [deleteMovieInfo] = useMutation(DELETE_MOVIE_DETAIL_INFO);

  function clickEvent() {
    deleteMovieInfo({variables: {
        id: props.match.params.id
    }});

    setTimeout(() => {
      window.location.href = '/movielist/1';
    }, 3000);
  }

  return (
    <Query query={GET_MOVIE_DETAIL_INFO} variables={{id: props.match.params.id}}>
      {({ loading, error, data }) => {
        if (loading) return '';
        if (error) return `Error! ${error.message}`;

        const movie_detail_info = data.getMovieDetailInfo;

        return (
          <React.Fragment>
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">삭제 확인</h5>
                    <button type="button" className="close" data-dismiss="modal">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <strong>삭제</strong> 하시겠습니까?
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-danger" onClick={clickEvent}>
                      <i className="fa fa-trash-o"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <DivBackgroundImage image_url='https://source.unsplash.com/2gNx4aKQYvY/1920x1080' min_height={window.innerHeight}>
              <div className="container">
                <div className="card border-0 shadow my-5">

                  <div className="row pl-4 pr-4 ml-5 mr-5 list-top-div">
                    <div className="col">
        
                    </div>
        
                    <div className="col-3 text-right">
                      {token && (<Link to={`/movieedit/${props.match.params.id}`}>
                        <button className="btn btn-secondary">
                          <i className="fa fa-pencil"></i>
                        </button>
                      </Link>)}

                    </div>

                    <div className="col-0">
                      {token && (<button className="btn btn-danger" data-toggle="modal" data-target="#exampleModal">
                        <i className="fa fa-trash-o"></i>
                      </button>)}
                    </div>

                    <div className="col-7">
                      <form onSubmit={(event) => {
                          event.preventDefault();
                          localStorage.setItem(SEARCH_TYPE, event.target.searchtype.value);
                          window.location.href = `/movieresultlist/${event.target.searchtext.value}/1`;
                      }}>
                        <SearchBar page_type={1}/>
                      </form>
                    </div>
                  </div>
        
                  <div className="row pl-4 pr-4 ml-5 mr-5 mt-2">
                    <hr width="100%" align="center"></hr>
                  </div>

                  <div className="row ml-5 mr-5">

                    <div className="col-md-6 mb-4">
                      <div className="p-4">
                        <img src={S3_IMAGE_URL + movie_detail_info.id} className="img-fluid mx-auto d-block detail-image" alt="" />
                      </div>
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className="p-4">
                        <p className="lead font-weight-bold">{movie_detail_info.title}</p>
                        <p>{movie_detail_info.sub_title}</p>
                        <br></br>
                        <p className="lead">{movie_detail_info.type}<br></br>
                        {movie_detail_info.watched === 'y' && (
                          <span className="badge badge-success">Watch</span>
                        )}
                        <span> </span>
                        {movie_detail_info.review !== '' && (
                            <span className="badge badge-info">Review</span>
                        )}
                        </p>
                        <br></br>
                        <p className="lead font-weight-bold">감독</p>
                        <p>{movie_detail_info.director}</p>
                        <br></br>
                        <p className="lead font-weight-bold">출연</p>
                        <p>{movie_detail_info.actor}</p>
                      </div>
                    </div>

                    <div className="row pt-2 pl-5 pr-5 pb-5">
                      <div className="movie-detail-bottom">
                        <div className="pb-4">
                          <p className="lead font-weight-bold">줄거리</p>
                          <p className="white-space-p">{movie_detail_info.story}</p>
                        </div>
                        <div>
                          <p className="lead font-weight-bold">감상</p>
                          <p className="white-space-p">{movie_detail_info.review}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </DivBackgroundImage>
          </React.Fragment>
        );
      }}
    </Query>
  );
}

export default MovieDetail;
