import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import './AlphabetBar.css'

function AlphabetBar(props) {
    function clickEvent0() {
        localStorage.setItem('ALPHA_TYPE', 0);
        window.location.href = `${props.link_text}1`;
    }

    let alphabet_list1 = ['ㄱ', 'ㄴ', 'ㄷ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅅ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];
    let alphabet_type_list1 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'];
    let start_char1 = ['가', '나', '다', '라', '마', '바', '사', '아', '자', '차', '카', '타', '파', '하'];
    let end_char1 = ['나', '다', '라', '마', '바', '사', '아', '자', '차', '카', '타', '파', '하', '힤'];

    function clickEvent1(index) {
        localStorage.setItem('ALPHA_TYPE', alphabet_type_list1[index]);
        localStorage.setItem('S_CHAR', start_char1[index]);
        localStorage.setItem('E_CHAR', end_char1[index]);
        window.location.href = `${props.link_text}1`;
    }

    let alphabet_list2 = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    let alphabet_type_list2 = ['15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40'];
    let start_char2 = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    let end_char2 = ['b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '['];

    function clickEvent2(index) {
        localStorage.setItem('ALPHA_TYPE', alphabet_type_list2[index]);
        localStorage.setItem('S_CHAR', start_char2[index]);
        localStorage.setItem('E_CHAR', end_char2[index]);
        window.location.href = `${props.link_text}1`;
    }

    let alphabet_list3 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let alphabet_type_list3 = ['41', '42', '43', '44', '45', '46', '47', '48', '49', '50'];
    let start_char3 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let end_char3 = ['1', '2', '3', '4', '5', '6', '7', '8', '9', ':'];

    function clickEvent3(index) {
        localStorage.setItem('ALPHA_TYPE', alphabet_type_list3[index]);
        localStorage.setItem('S_CHAR', start_char3[index]);
        localStorage.setItem('E_CHAR', end_char3[index]);
        window.location.href = `${props.link_text}1`;
    }

    const curr_type = localStorage.getItem('ALPHA_TYPE');

    return (
        <div>
            <Link to='#' onClick={clickEvent0}>
                <span className={`badge ${parseInt(0) === parseInt(curr_type) ? "badge-dark" : "badge-secondary"} small_alphabet`}>All</span>
            </Link>
            <br></br>
            {alphabet_list1.map((value, index) => (
                <Fragment key={index}>
                    <Link to='#' onClick={() => clickEvent1(index)}>
                        <span className={`badge ${parseInt(alphabet_type_list1[index]) === parseInt(curr_type) ? "badge-dark" : "badge-secondary"} small_alphabet`}>{value}</span>
                    </Link>
                    <span> </span>
                </Fragment>
            ))}
            <br></br>
            {alphabet_list2.map((value, index) => (
                <Fragment key={index}>
                    <Link to='#' onClick={() => clickEvent2(index)}>
                        <span className={`badge ${parseInt(alphabet_type_list2[index]) === parseInt(curr_type) ? "badge-dark" : "badge-secondary"} small_alphabet`}>{value}</span>
                    </Link>
                    <span> </span>
                </Fragment>
            ))}
            <br></br>
            {alphabet_list3.map((value, index) => (
                <Fragment key={index}>
                    <Link to='#' onClick={() => clickEvent3(index)}>
                        <span className={`badge ${parseInt(alphabet_type_list3[index]) === parseInt(curr_type) ? "badge-dark" : "badge-secondary"} small_alphabet`}>{value}</span>
                    </Link>
                    <span> </span>
                </Fragment>
            ))}
        </div>
    );
}

export default AlphabetBar;