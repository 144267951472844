import React, { useState, useEffect } from 'react';

import JoditEditor from 'jodit-react';

import gql from "graphql-tag";
import { useQuery, useMutation } from "react-apollo";

import { DivBackgroundImageFixed } from '../../Style';

import './BoardEdit.css'

const LAST_PAGE = 'last_page';

const GET_BOARD_DETAIL_INFO = gql`
  query getBoardDetailInfo($id: String) {
    getBoardDetailInfo(id: $id) {
      id
      title
      content
      write_date
    }
  }
`;

const EDIT_BOARD_DETAIL_INFO = gql`
    mutation editBoardInfo($id: String, $title: String, $content: String, $write_date: String) {
      editBoardInfo(id: $id, title: $title, content: $content, write_date: $write_date) {
          id
          title
          content
          write_date
        }
    }
`;

function BoardEdit(props) {
  let [title, setTitle] = useState('');
  let [content, setContent] = useState('');
  const [alertshow, setAlertShow] = useState(false);

  const { loading, error, data } = useQuery(GET_BOARD_DETAIL_INFO, {
      variables: {id: props.match.params.id},
  });

  const [editBoardInfo] = useMutation(EDIT_BOARD_DETAIL_INFO);

  useEffect(() => {
      setTitle(data.getBoardDetailInfo.title);
      setContent(data.getBoardDetailInfo.content);
  }, [data]);

  function clickEvent() {
    if (loading || error) return;
    
    setContent(content);
    setTitle(title);
    if (title === '')
    {
        setAlertShow(true);
        return;
    }

    const id = data.getBoardDetailInfo.id;
    const write_date = data.getBoardDetailInfo.write_date;
    editBoardInfo({variables: {
        id,
        title,
        content,
        write_date,
    }});

    setTimeout(() => {
        window.history.pushState(null, '', localStorage.getItem(LAST_PAGE));
        window.location.href = `/boarddetail/${id}`;
    }, 3000);
  }

  if (loading) return '';
  if (error) return `Error! ${error.message}`;

  return (
    <DivBackgroundImageFixed image_url='https://source.unsplash.com/2gNx4aKQYvY/1920x1080' min_height={window.innerHeight}>
      <div className="container">
        <div className="card border-0 shadow my-5">

          <div className="row pl-4 pr-4 ml-5 mr-5 mt-3 text-center">
            <div className="col-9">
                {alertshow && (
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>제목</strong>을 입력해주세요.
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setAlertShow(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                )}
            </div>
            <div className="col text-right">
                <button className="btn btn-secondary edit-button-align" onClick={clickEvent}>
                    <i className="fa fa-save"></i>
                </button>
            </div>
          </div>

          <div className="row pl-4 pr-4 ml-5 mr-5 mb-1">
          <hr width="100%" align="center"></hr>
            <div className="p-4 col-md-12">
              <p className="lead font-weight-bold">
                <input type="text" id="title" className="form-control" defaultValue={title} required onChange={(event) => {
                  title = event.target.value;
                }} />
              </p>

              <div className="row pb-4 text-center">
                <hr width="100%" align="center"></hr>
              </div>

              <JoditEditor
                value={content}
                config={{
                  spellcheck: false,
                  showCharsCounter: false,
                  showWordsCounter: false,
                  showXPathInStatusbar: false,
                  allowResizeY: false,
                  height: 320,
                  readonly: false,
                  uploader: { insertImageAsBase64URI: true },
                  buttons: ['bold', 'strikethrough', 'underline', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|', 'image', 'table', '|', 'align', 'hr'],
                  toolbarAdaptive: false,
                  placeholder: '',
                  disablePlugins: ['paste', 'drag-and-drop', 'drag-and-drop-element'],
                  askBeforePasteHTML: false,
                  askBeforePasteFromWord: false
                }}
                onBlur={newContent => { content = newContent; }}
                onChange={newContent => {}}
              />
            </div>
          </div>

          <div className="row pl-4 pr-4 ml-5 mr-5 mb-3 text-center">
            <hr width="100%" align="center"></hr>
            <div className="col-9">
                {alertshow && (
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>제목</strong>을 입력해주세요.
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setAlertShow(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                )}
            </div>
            <div className="col text-right">
                <button className="btn btn-secondary edit-button-align" onClick={clickEvent}>
                    <i className="fa fa-save"></i>
                </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row p-4 ml-5 mr-5 mb-3 text-center">
      </div>
    </DivBackgroundImageFixed>
  );
}

export default BoardEdit;
